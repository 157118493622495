import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'second', loadChildren: './second/second.module#SecondModule'},
  { path: 'user', loadChildren: './user/user.module#UserModule'},
  { path: 'management', loadChildren: './management/management.module#ManagementModule' },
];

@NgModule({ 
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }